<template>
  <div>
    <Modal v-model="exportFieldsModal" :title="exportFieldsTitle" width="900" :transfer="false" class-name="exportFieldsStyle"
      :mask-closable="false" @on-visible-change="exportFieldsChange">
      <Alert show-icon>
        <div class="flex align-items-center justify-content-between">
          <span>{{ $t('key1000563') }}</span>
          <router-link tag="a" target="_blank" to="/exportTask" class="cursor" :class="themeColors">{{ $t('key1000564') }}</router-link>
        </div>
      </Alert>
      <!--导出模板-->
      <div class="flex align-items-center mb10">
        <span class="font-size-14 font-weight-bold color-333">{{ $t('key1000565') }}</span>
        <Dropdown class="select-dropdown-menu-styles" trigger="click">
          <Button type="default" class="wp100">
            <p class="exportTemplateName_box">{{ exportTemplateName }}</p>
            <Icon type="ios-arrow-down"/>
          </Button>
          <template #list>
            <Dropdown-menu>
              <Dropdown-item v-for="item in exportTemplateList" :key="item.ymsExportTemplateId">
                <div class="flex align-items-center">
                  <p @click="selectExportTemplateBtn(item)" :class="optionType === 'look' ? 'wp100' : ''">{{ item.name }}</p>
                  <template v-if="optionType === 'edit'">
                    <!--编辑导出模板-->
                    <Icon
                      type="md-create"
                      class="font-size-15 cursor ml6 mr6"
                      :class="themeColors"
                      @click="editExportTemplateBtn(item.ymsExportTemplateId)"/>
                    <!--删除导出模板-->
                    <Icon
                      type="md-close"
                      class="font-size-17 cursor"
                      style="color: #cf2a28;"
                      @click="delExportTemplateBtn(item.ymsExportTemplateId)"/>
                  </template>
                </div>
              </Dropdown-item>
            </Dropdown-menu>
          </template>
        </Dropdown>
        <div class="ml10 cursor font-size-13" v-if="optionType === 'look'" :class="themeColors" @click="optionType = 'edit'">{{ $t('key1000566') }}</div>
        <div class="flex align-items-center cursor ml15" style="color: #d13131;" v-else @click="optionType = 'look'">
          <span class="icon iconfont font-size-15">&#xe62f;</span>
          <span class="font-size-14 pt3 ml3">{{ $t('key1000567') }}</span>
        </div>
      </div>
      <!--时间范围-->
      <div class="flex align-items-center mb10" v-if="timeFiltering">
        <span class="font-size-14 font-weight-bold color-333">{{ $t('key1000568') }}</span>
        <Date-picker
          transfer
          type="datetimerange"
          class="width_350"
          @on-clear="resetDate"
          @on-change="getDateValue"
          :options="options"
          :clearable="true"
          format="yyyy-MM-dd HH:mm:ss"
          placement="bottom-end"
          :placeholder="$t('key1000561')"
          :value="timerList">
        </Date-picker>
      </div>
      <!--导出维度-->
      <div class="flex align-items-center mb10 mt15" v-if="dimensionTypeList.length>0">
        <span class="title">{{ $t('key1000569') }}</span>
        <RadioGroup v-model="dimensionType" @on-change="(val)=>handleDimensionData(val, true)">
          <template v-for="(item,index) in dimensionTypeList">
            <Radio :key="index" :label="item.value" class="mr30" v-if="item.show">
              <span>{{ item.label }}</span>
              <span v-if="item.desc" class="font-size-13" style="color: #8D8D8D">
            <span>{{ item.desc }}</span>
            <Tooltip max-width="300" placement="top" class="ml5 cursor" transfer v-if="item.iconCenter" :content="item.iconCenter">
                <Icon size="18" type="md-help-circle"/>
            </Tooltip>
          </span>
            </Radio>
          </template>
        </RadioGroup>
      </div>
      <h2 class="title mb10">{{ $t('key1000570') }}</h2>
      <Checkbox class="mt5 mb10" style="display: inline-block;" v-model="exportFieldsAll"
        :indeterminate="indeterminate" @on-change="changeFieldsAll">
        {{ $t('key1000571') }}
      </Checkbox>
      <!--勾选项-->
      <div class="exportFields_box">
        <template v-for="(item, index) in fieldsList">
          <div :class="handleHideTitle(item) ?'exportFields_box_item' : ''">
            <h3 class="fields_title" v-if="handleHideTitle(item)">{{ item.title }}</h3>
            <CheckboxGroup v-model="item.selectList" @on-change="(val)=>checkBoxChange(val, item, index)">
              <Row type="flex">
                <draggable v-model="item.checkBoxList" class="flex flex-wrap wid100P" @end="(data)=>draggableEnd(data,index)">
                  <!--可拖动排序的字段-->
                  <template v-for="(ele, idx) in item.checkBoxList.filter((k)=>!k.disabledDrag)">
                    <Col :xxl="fourItemCol" :xl="fourItemCol" :lg="fourItemCol" :md="fourItemCol">
                      <div class="flex align-items-center mb8 moveIcon">
                        <Icon type="ios-apps" class="font-size-17 mr5" style="cursor: move;"/>
                        <Checkbox :disabled="ele.disabled" :label="ele.paramKey" :key="idx" style="display: inline-block;">
                          {{ ele.paramValue }}
                        </Checkbox>
                      </div>
                    </Col>
                  </template>
                </draggable>
                <!--不可以拖动排序的字段-->
                <template v-for="(ele, idx) in item.checkBoxList.filter((k)=>k.disabledDrag)">
                  <Col :xxl="fourItemCol" :xl="fourItemCol" :lg="fourItemCol" :md="fourItemCol">
                    <div class="flex align-items-center mb8 moveIcon">
                      <Checkbox :disabled="ele.disabled" :label="ele.paramKey" :key="idx" style="display: inline-block;">
                        {{ ele.paramValue }}
                      </Checkbox>
                    </div>
                  </Col>
                </template>
              </Row>
            </CheckboxGroup>
          </div>
        </template>
      </div>
      <template #footer>
        <div class="flex align-items-center" :class="!ymsExportTemplateId ? 'justify-content-between': 'justify-content-end'">
          <div class="flex align-items-center" v-if="!ymsExportTemplateId">
            <Checkbox v-model="saveNewTemplate">{{ $t('key1000572') }}</Checkbox>
            <template v-if="saveNewTemplate">
              <Input v-model.trim="templateName" :placeholder="$t('key1000562')" style="width: 250px; margin: 0 8px;" @on-enter="addExportTemplateBtn"/>
              <Button @click="addExportTemplateBtn">{{ $t('key1000361') }}</Button>
            </template>
          </div>
          <div class="flex align-items-center">
            <Button class="mr15" @click="exportFieldsModal = false">{{ $t('key1000097') }}</Button>
            <Button class="mr15" type="primary" @click="exportBtn"
              :disabled="exportTalg || (timeFiltering && timerList.length <=0)">{{ $t('key1000573') }}</Button>
          </div>
        </div>
      </template>
    </Modal>
    <!--编辑导出模板-->
    <editExportTemplateModal
      ref="editExportTemplate"
      @updateExportTemplate="updateExportTemplate"
      :dimensionTalg="dimensionTalg"
      :customFieldExportList="customFieldExportList"
      :dimensionTypeList="dimensionTypeList">
    </editExportTemplateModal>
  </div>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import draggable from 'vuedraggable';
import editExportTemplateModal from "@/components/common/editExportTemplateModal.vue";

export default {
  name: "exportFieldsModal",
  mixins: [Mixin],
  props: {
    exportFieldsTitle: { // 导出的标题
      type: String,
      default: ''
    },
    dimensionTypeList: {  // 导出数据维度类型的基础数据
      type: Array,
      default: () => {
        return []
      }
    },
    paramKeyColums: { // 自定义导出列表字段的key
      type: String,
      default: ''
    },
    customFieldExportList: { // 自定义导出字段的基础数据
      type: Array,
      default: () => []
    },
    dimensionTalg: { // 按照不同的的维度展示不同的数据，默认是不同的维度展示相同的数据
      type: Boolean,
      default: false
    },
    timeFiltering: { // 时间范围筛选
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fieldsList: [],
      exportFieldsModal: false,
      exportTalg: true,
      exportFieldsAll: false,
      indeterminate: false,
      dimensionType: 1,
      cacheTypeObj: {}, // 缓存当前维度下已经选择的数据
      defaultCheckObj: null,
      recordSortList: {},
      exportTemplateName: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000574'),
      exportTemplateList: [],
      optionType: 'look',
      saveNewTemplate: true,
      templateName: '',
      ymsExportTemplateId: null,
      selectExportTemplateTalg: false,
      updateExportTemplateTalg: false,
      updateTemplateTalg: false,
      timerList: [],
      selectStartTime: null,
      selectEndTime: null,
      options: {
        disabledDate(date) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // 设置为今天的 00:00:00
          const ninetyDaysAgo = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000); // 90天前的日期
          // 返回 true 表示日期不可选，false 表示日期可选
          return date && (date < ninetyDaysAgo || date > today);
        }
      },
    }
  },
  computed: {
    // 区分主题颜色
    themeColors() {
      // 分销商系统
      if (this.system === 'distributor') {
        return 'distributorThemeColor'
      }
      // 运营、供应商系统
      else {
        return 'themeColor'
      }
    },
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  methods: {
    // 获取当前用户设置的默认导出字段
    getExportFieldsSetting() {
      let v = this;
      v.getExportTemplateList().then(() => {
        let query = {
          businessId: v.userInfo.userId,
          paramKeys: [v.paramKeyColums]
        }
        v.defaultCheckObj = null;
        if (v.paramKeyColums) {
          v.axios.post(api.post_erpCommonSettingParam_queryByUK, query, {loading: true}).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              v.handleExportFields(data);
            }
          });
        } else {
          v.exportFieldsModal = true;
        }
      });
    },
    // 处理导出数据
    handleExportFields(data, ymsExportTemplateId) {
      let v = this;
      v.exportFieldsModal = true;
      if (data && data.length > 0) {
        v.defaultCheckObj = JSON.parse(data[0].paramValue) || null;
        if (v.exportTemplateList.length > 0) {
          if (!v.updateTemplateTalg) {
            v.ymsExportTemplateId = v.defaultCheckObj.ymsExportTemplateId || ymsExportTemplateId || null;
          }
          if (v.ymsExportTemplateId) {
            v.selectExportTemplateTalg = true;
            v.updateTemplateTalg = false;
            v.exportTemplateList.map((item) => {
              if (item.ymsExportTemplateId === v.ymsExportTemplateId) {
                v.exportTemplateName = item.name;
              }
            })
          }
        }
        // 默认选中有权限的维度
        if (v.dimensionTypeList && v.dimensionTypeList.length > 0) {
          let type = 1;
          let list = v.dimensionTypeList.filter((item) => {
            return item.show
          });
          if (list.length > 0) {
            type = list[0].value;
            list.map((ele) => {
              if (v.defaultCheckObj.dimensionType === ele.value) {
                type = ele.value;
              }
            });
          }
          v.dimensionType = v.defaultCheckObj.dimensionType || type;
        }
      }
      if (v.defaultCheckObj) {
        for (let key in v.defaultCheckObj) {
          if (key !== 'dimensionType') {
            let list = v.defaultCheckObj[key];
            if (typeof list[0] === 'object' && list[0] !== null) {
              v.recordSortList[key] = list.map((ele) => {
                return ele.columnName;
              })
            } else {
              v.recordSortList[key] = list;
            }
          }
        }
      }
      v.handleDimensionData(v.defaultCheckObj, false);
      v.$forceUpdate();
    },
    // 更新状态
    updateStatus() {
      let v = this;
      v.selectExportTemplateTalg = true;
      v.$nextTick(() => {
        v.selectExportTemplateTalg = false;
      })
    },
    // 按照不同的维度处理数据
    handleDimensionData(obj, val) {
      let v = this;
      if (val) {
        v.updateStatus();
      }
      let newData = JSON.parse(JSON.stringify(v.customFieldExportList));
      // 根据指定的key进行排序
      if (Object.keys(v.recordSortList).length > 0) {
        newData.map((item) => {
          const titleKey = item.titleKey;
          const sortList = v.recordSortList[titleKey] || [];
          if (sortList.length > 0) {
            item.checkBoxList.sort((a, b) => {
              const indexA = sortList.indexOf(a.paramKey);
              const indexB = sortList.indexOf(b.paramKey);
              return indexA - indexB;
            });
          }
        });
      }
      v.fieldsList = [];
      v.exportFieldsAll = false;
      v.indeterminate = false;
      // 是否需要按照不同的维度展示数据
      setTimeout(() => {
        if (v.dimensionTypeList.length > 0) {
          if (v.dimensionTalg) {
            let newList = newData.map(item => {
              item.checkBoxList = item.checkBoxList.filter(ele => ele.type.includes(v.dimensionType));
              return item;
            }).filter(talg => talg.checkBoxList.length > 0);
            v.handleCheckBoxData(obj, newList);
          } else {
            v.handleCheckBoxData(obj, newData);
          }
        } else {
          v.handleCheckBoxData(obj, newData);
        }
      }, 50);
    },
    // 处理选中的数据回显
    handleCheckBoxData(obj, newData) {
      let keyList = [];
      if (Object.keys(this.cacheTypeObj).length > 0 && this.cacheTypeObj[this.dimensionType]
        && this.cacheTypeObj[this.dimensionType].length > 0) {
        keyList = this.cacheTypeObj[this.dimensionType]
      }
      newData.map((item) => {
        let list = [];
        if (obj && Object.keys(obj).length > 0) {
          // 按照维度回显对应的数据
          if (this.dimensionTypeList.length > 0) {
            let arr = [];
            let dimensionType = obj['dimensionType'] || null;
            if (dimensionType === this.dimensionType) {
              let newList = obj[item.titleKey] || [];
              if (newList.length > 0) {
                if (typeof newList[0] === 'object' && newList[0] !== null) {
                  newList.map((ele) => {
                    if (ele.isChoose === '1') {
                      arr.push(ele.columnName);
                    }
                  });
                } else {
                  arr = obj[item.titleKey] || [];
                }
              }
            }
            if (item.selectList.length > 0) {
              let list = [...item.selectList, ...arr];
              item.selectList = this.uniqueFunc(list);
            } else {
              item.selectList = arr || [];
            }
          } else {
            let arr = [];
            let newList = obj[item.titleKey] || [];
            if (newList.length > 0) {
              if (typeof newList[0] === 'object' && newList[0] !== null) {
                newList.map((ele) => {
                  if (ele.isChoose === '1') {
                    arr.push(ele.columnName);
                  }
                });
              } else {
                arr = obj[item.titleKey] || [];
              }
            }
            if (item.selectList.length > 0) {
              let list = [...item.selectList, ...arr];
              item.selectList = this.uniqueFunc(list);
            } else {
              item.selectList = arr || [];
            }
          }
        } else {
          let newList = [];
          let selectKeyList = [];
          let dimensionType = null;
          if (this.defaultCheckObj && Object.keys(this.defaultCheckObj).length > 0) {
            dimensionType = this.defaultCheckObj['dimensionType'] || null;
            selectKeyList = this.defaultCheckObj[item.titleKey] || [];
          }
          if (this.dimensionTypeList.length > 0) {
            if (dimensionType === this.dimensionType) {
              newList = handleData(selectKeyList)
            }
          } else {
            newList = handleData(selectKeyList)
          }
          let arr = [...newList, ...keyList];
          this.cacheTypeObj[this.dimensionType] = arr;
          item.checkBoxList.map((ele) => {
            if (arr.includes(ele.paramKey)) {
              list.push(ele.paramKey);
            }
          });
          if (item.selectList.length > 0) {
            let newList = [...item.selectList, ...list];
            item.selectList = this.uniqueFunc(newList);
          } else {
            item.selectList = list;
          }
        }
      });

      function handleData(selectKeyList) {
        let newList = [];
        if (selectKeyList.length > 0) {
          if (typeof selectKeyList[0] === 'object' && selectKeyList[0] !== null) {
            selectKeyList.map((ele) => {
              if (ele.isChoose === '1') {
                newList.push(ele.columnName);
              }
            });
          } else {
            newList = selectKeyList;
          }
        }
        return newList;
      }

      this.fieldsList = newData;
      this.$forceUpdate();
    },
    // 处理导出字段的参数
    handleExportFieldsSetting() {
      let v = this;
      let paramValueObj = {};
      v.fieldsList.map((item) => {
        paramValueObj[item.titleKey] = [];
        item.checkBoxList.map((talg) => {
          paramValueObj[item.titleKey].push({
            columnName: talg.paramKey,
            isChoose: '0',
          })
        });
      });
      if (Object.keys(paramValueObj).length > 0) {
        v.fieldsList.map((item) => {
          if (item.selectList.length > 0) {
            let list = paramValueObj[item.titleKey];
            item.selectList.map((key) => {
              list.map((talg) => {
                if (talg.columnName === key) {
                  talg.isChoose = '1';
                }
              });
            });
          }
        });
      }
      // 如果是按照维度导出的，需要存维度类型，用于回显显示那个维度下的数据
      if (v.dimensionTypeList.length > 0) {
        paramValueObj['dimensionType'] = v.dimensionType;
      }
      // 如果是选中模板导出需要记录对应的导出模板的id，用户回显数据
      if (v.ymsExportTemplateId) {
        paramValueObj['ymsExportTemplateId'] = v.ymsExportTemplateId;
      }
      // 根据指定的key进行排序
      if (Object.keys(v.recordSortList).length > 0) {
        for (let key in paramValueObj) {
          if (!['dimensionType', 'ymsExportTemplateId'].includes(key)) {
            let sortList = v.recordSortList[key] || [];
            let list = paramValueObj[key] || [];
            if (sortList.length > 0) {
              list.sort((a, b) => {
                const indexA = sortList.indexOf(a.columnName);
                const indexB = sortList.indexOf(b.columnName);
                return indexA - indexB;
              });
              paramValueObj[key] = list;
            }
          }
        }
      }
      return paramValueObj;
    },
    // 保存设置导出的字段
    saveExportFieldsSetting() {
      let v = this;
      return new Promise(resolve => {
        let queryList = [
          {
            businessId: v.userInfo.userId,
            paramKey: v.paramKeyColums,
            paramValue: JSON.stringify(v.handleExportFieldsSetting()),
          }
        ];
        v.axios.post(api.post_erpCommonSettingParam_saveList, queryList).then(res => {
          if (res.data.code === 0) {
            let obj = {
              businessId: v.userInfo.userId,
              paramKey: v.paramKeyColums,
              paramValue: JSON.stringify(v.handleExportFieldsSetting()),
              dimensionType: v.dimensionType
            }
            resolve(obj);
          }
        });
      });
    },
    // 派发导出操作事件
    exportBtn() {
      let obj = {};
      if (this.dimensionTypeList.length > 0) {
        obj.dimensionType = this.dimensionType;
      }
      if (this.timeFiltering) {
        obj.selectStartTime = this.selectStartTime || null;
        obj.selectEndTime = this.selectEndTime || null;
      }
      this.$emit('exportFieldsDataBtn', obj);
    },
    // 监听弹窗
    exportFieldsChange(value) {
      if (value) {
        this.stopScroll();
      } else {
        this.removeStopScroll();
        this.fieldsList = [];
        this.cacheTypeObj = {};
        this.defaultCheckObj = null;
        this.exportTalg = true;
        this.recordSortList = {};
        this.exportTemplateName = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000574');
        this.optionType = 'look';
        this.templateName = '';
        this.ymsExportTemplateId = null;
        this.updateExportTemplateTalg = false;
        this.selectExportTemplateTalg = false;
        this.updateTemplateTalg = false;
        this.timerList = [];
        this.resetDate();
      }
    },
    // 全选
    changeFieldsAll(value) {
      let v = this;
      v.updateStatus();
      v.defaultCheckObj = null;
      let selectList = [];
      if (value) {
        v.fieldsList.map((item) => {
          item.selectList = item.checkBoxList.map((ele) => {
            selectList.push(ele.paramKey)
            return ele.paramKey
          });
        })
      } else {
        v.fieldsList.map((item) => {
          item.selectList = [];
          item.checkBoxList.map((ele) => {
            if (ele.disabled) {
              item.selectList.push(ele.paramKey);
              selectList.push(ele.paramKey);
            }
          });
        });
        v.cacheTypeObj[v.dimensionType] = selectList;
      }
    },
    // 处理隐藏标题
    handleHideTitle(data) {
      let val = true;
      let talg = data.checkBoxList.some((item) => {
        return item.type && item.type.length > 0
      });
      if (talg) {
        val = data.checkBoxList[0].type.includes(this.dimensionType);
      } else {
        val = true;
      }
      return val;
    },
    // 监听数据变化实时切换选中状态
    handleCheckBoxStatus(data) {
      let selectList = [];
      let checkBoxList = [];
      data.map((item) => {
        item.selectList.map((ele) => {
          selectList.push(ele);
        });
        item.checkBoxList.map((talg) => {
          checkBoxList.push(talg);
        })
      });
      if (selectList.length > 0) {
        this.indeterminate = true;
        if (selectList.length >= checkBoxList.length) {
          this.exportFieldsAll = true;
          this.indeterminate = false;
        }
        this.cacheTypeObj[this.dimensionType] = selectList;
      } else {
        this.exportFieldsAll = false;
        this.indeterminate = false;
      }
      this.exportTalg = selectList.length <= 0;
      this.$forceUpdate();
    },
    // 勾选中导出的字段值
    checkBoxChange(val, item) {
      this.updateStatus();
      if (this.defaultCheckObj) {
        let list = this.defaultCheckObj[item.titleKey] || [];
        if (list.length > 0) {
          this.defaultCheckObj = null;
        }
      }
      this.$forceUpdate();
    },
    // 拖动排序结束之后
    draggableEnd({oldIndex, newIndex}, currentIndex) {
      let v = this;
      v.updateStatus();
      let selectList = v.fieldsList[currentIndex].selectList;
      let originalSelectList = JSON.parse(JSON.stringify(selectList));
      v.fieldsList[currentIndex].selectList = [];
      v.fieldsList.map((item) => {
        v.recordSortList[item.titleKey] = item.checkBoxList.map((ele) => {
          return ele.paramKey
        })
      });
      setTimeout(() => {
        v.$set(v.fieldsList[currentIndex], 'selectList', originalSelectList);
        v.$forceUpdate();
      }, 100)
    },
    // 获取当前用户所有导出模板数据
    getExportTemplateList() {
      let v = this;
      v.exportTemplateList = [];
      let query = {
        businessId: v.userInfo.userId,
        paramKey: v.paramKeyColums
      }
      return new Promise((resolve) => {
        v.axios.post(api.post_ymsExportTemplate_queryList, query).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            v.exportTemplateList = data;
            if (data.length <= 0) {
              v.updateStatus();
            }
            if (v.ymsExportTemplateId) {
              v.exportTemplateList.map((item) => {
                if (item.ymsExportTemplateId === v.ymsExportTemplateId) {
                  v.exportTemplateName = item.name;
                }
              })
            }
            resolve(data);
          }
        });
      })
    },
    // 选择导出模板
    selectExportTemplateBtn(item) {
      let v = this;
      v.exportTemplateName = item.name;
      v.ymsExportTemplateId = item.ymsExportTemplateId;
      v.selectExportTemplateTalg = true;
      v.defaultCheckObj = null;
      let newData = v.exportTemplateList.filter((ele) => {
        return ele.ymsExportTemplateId === item.ymsExportTemplateId
      });
      v.defaultCheckObj = null;
      this.handleExportFields(newData, item.ymsExportTemplateId);
    },
    // 更新导出模板数据
    updateExportTemplate(data) {
      if (this.updateExportTemplateTalg) {
        this.getExportTemplateList().then(() => {
          this.selectExportTemplateBtn(data);
        });
      } else {
        this.getExportTemplateList();
      }
    },
    // 新增导出模板
    addExportTemplateBtn() {
      let v = this;
      if (v.templateName) {
        let query = {
          businessId: v.userInfo.userId,
          name: v.templateName,
          paramKey: v.paramKeyColums,
          paramValue: JSON.stringify(v.handleExportFieldsSetting())
        }
        v.axios.post(api.post_ymsExportTemplate_insert, query, {loading: true, loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')}).then(res => {
          if (res.data.code === 0) {
            v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000575'));
            v.ymsExportTemplateId = res.data.datas;
            v.templateName = '';
            v.getExportTemplateList();
          }
        });
      } else {
        v.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000576'));
        return false;
      }
    },
    // 编辑导出模板
    editExportTemplateBtn(ymsExportTemplateId) {
      this.updateExportTemplateTalg = false;
      if (this.ymsExportTemplateId === ymsExportTemplateId) {
        this.updateExportTemplateTalg = true;
      }
      this.$refs['editExportTemplate'].getExportTemplateDetail(ymsExportTemplateId);
    },
    // 删除导出模板
    delExportTemplateBtn(ymsExportTemplateId) {
      let v = this;
      v.$Modal.confirm({
        title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000577'),
        okText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000342'),
        cancelText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000097'),
        onOk: () => {
          v.axios.delete(api.delete_ymsExportTemplate + ymsExportTemplateId, null, {loading: true, loadingtext: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')}).then(res => {
            if (res.data.code === 0) {
              v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000578'));
              if (v.ymsExportTemplateId === ymsExportTemplateId) {
                v.exportTemplateName = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000574');
                v.ymsExportTemplateId = null;
                v.updateTemplateTalg = true;
                v.getExportFieldsSetting();
              } else {
                v.getExportTemplateList();
              }
            }
          });
        },
        onCancel: () => {

        }
      });
    },
    // 重置时间范围
    resetDate() {
      this.selectStartTime = null;
      this.selectEndTime = null;
    },
    // 获取日期返回值
    getDateValue(value) {
      let v = this;
      if (value.length === 0 || !value[0]) {
        v.resetDate();
      } else {
        if (value[0] !== '') {
          let timeList = v.defaultTimePeriod(value);
          v.timerList = v.defaultTimePeriod(value, false);
          v.selectStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.selectEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
  },
  watch: {
    // 监听导出字段值的变化
    fieldsList: {
      handler(data) {
        if (data && data.length > 0) {
          this.handleCheckBoxStatus(data);
        }
      },
      immediate: true,
      deep: true
    },
    // 监听是否勾选中保存为新模板
    saveNewTemplate: {
      handler(val) {
        if (!val) {
          this.templateName = '';
        }
      }
    },
    // 监听是否选中导出模板
    selectExportTemplateTalg: {
      handler(val) {
        if (!val) {
          this.exportTemplateName = aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000574');
          this.ymsExportTemplateId = null;
        }
      },
      deep: true
    }
  },
  components: {
    draggable,
    editExportTemplateModal
  }
}
</script>

<style lang="less" scoped>
/deep/ .exportFieldsStyle {

  .ivu-modal-body {
    padding-top: 8px;
  }

  .title {
    color: #333;
    font-size: 14px;
    font-weight: bold;
  }

  .select-dropdown-menu-styles {
    width: 350px;

    .ivu-btn {
      padding: 0 7px !important;

      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .exportTemplateName_box {
      display: inline-block;
      max-width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 10px;
    }

    .ivu-select-dropdown {
      max-height: 350px !important;
      overflow-y: auto;
    }
  }

  .exportFields_box {
    .exportFields_box_item {
      margin-bottom: 25px;

      .fields_title {
        font-size: 14px;
        color: #333;
        margin-bottom: 8px;
        font-weight: bold;
      }

      /*.ivu-checkbox-group-item {
        margin-bottom: 8px;
      }*/
    }
  }

  .ivu-alert {
    padding: 8px 14px 8px 38px;

    .ivu-alert-icon .ivu-icon {
      font-size: 20px;
    }
  }
}
</style>
